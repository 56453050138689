<template>
  <div v-if="!loading" class="loading">
    <div v-if="dataset && error.length === 0">
      <CardsListView
        v-if="dataset.type === 'list'"
        :assets="assets"
      ></CardsListView>
      <CardGridView
        v-else-if="dataset.type === 'grid'"
        :itemsPerRowResponsive="responsiveFinal"
        :assets="assets"
        :gutter="dataset.gutter === '' ? 12 : dataset.gutter"
      >
        <template v-slot:default="slotProps">
          <CardTwo v-if="dataset.cardType === '2'" :asset="slotProps.asset" />
          <CardThree v-if="dataset.cardType === '3'" :asset="slotProps.asset" />
          <CardFour v-if="dataset.cardType === '4'" :asset="slotProps.asset" />
          <CardOne v-if="dataset.cardType === '1'" :asset="slotProps.asset" />
        </template>
      </CardGridView>

      <NftCarousel
        v-else-if="dataset.type === 'carousel'"
        :itemsPerRowResponsive="responsiveFinal"
        :assets="assets"
      >
        <template v-slot:default="slotProps">
          <template v-if="dataset.cardType === undefined">
            <CardOne :asset="slotProps.asset" />
          </template>
          <template v-else>
            <CardTwo v-if="dataset.cardType === '2'" :asset="slotProps.asset" />
            <CardThree
              v-else-if="dataset.cardType === '3'"
              :asset="slotProps.asset"
            />
            <CardFour
              v-else-if="dataset.cardType === '4'"
              :asset="slotProps.asset"
            />
            <CardOne v-else :asset="slotProps.asset" />
          </template>
        </template>
      </NftCarousel>
      <template v-else>
        <CardOne
          v-if="dataset.cardType === '1'"
          :asset="assets[0]"
        />
        <CardTwo
          v-if="dataset.cardType === '2'"
          :asset="assets[0]"
        />
        <CardThree
          v-if="dataset.cardType === '3'"
          :asset="assets[0]"
        />
        <CardFour
          v-if="dataset.cardType === '4'"
          :asset="assets[0]"
        />
      </template>
    </div>
    <div v-else>
      {{ error }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch, defineAsyncComponent } from "vue";
import fetchAssets, { APISettings } from "./functions/fetchAssets";
import { mergeWith } from "lodash";

export default defineComponent({
  name: "App",
  components: {
    CardOne :  defineAsyncComponent(() => import(/* webpackChunkName: "CardOne" */ "./components/CardOne/CardOne.vue")),
    CardTwo : defineAsyncComponent(() => import(/* webpackChunkName: "CardTwo" */ "./components/CardTwo/CardTwo.vue")),
    CardThree : defineAsyncComponent(() => import(/* webpackChunkName: "CardThree" */ "./components/CardThree/CardThree.vue")),
    CardFour : defineAsyncComponent(() => import(/* webpackChunkName: "CardFour" */ "./components/CardFour/CardFour.vue")),
    NftCarousel : defineAsyncComponent(() => import(/* webpackChunkName: "NftCarousel" */ "./components/NftCarousel/NftCarousel.vue")),
    CardsListView : defineAsyncComponent(() => import(/* webpackChunkName: "CardsListView" */ "./components/CardsListView/CardsListView.vue")),
    CardGridView : defineAsyncComponent(() => import(/* webpackChunkName: "CardGridView" */ "./components/CardGridView/CardGridView.vue"))
  },
  props: {
    dataset: Object as PropType<DOMStringMap>,
  },
  setup(props) {
    let settings: APISettings = {
      order_direction: "desc",
      limit: 20,
      order_by: "sale_date",
    };
    if (props.dataset !== undefined) {
      const addresses : string[] = (props.dataset.addresses as string)?.replaceAll(/\s/g,'').split(",");
      const tokens : string[] = (props.dataset.tokens as string)?.replaceAll(/\s/g,'').split(",");

      settings = {
        owner: props.dataset.owner,
        order_direction: props.dataset.direction,
        limit: (props.dataset.limit ?? addresses.length ?? 10) as number,
        order_by: props.dataset.order ?? "sale_date",
      };
      if(props.dataset.collection !== "") {
        settings.collection = props.dataset.collection as string;
      }
      if(props.dataset.addresses !== "") {
        settings.asset_contract_addresses= addresses;
      }
      if(props.dataset.tokens !== "") {
        settings.token_ids= tokens;
      }
      const responsiveFinal = mergeWith(
        {},
        props.dataset.responsive ===undefined ? {} : JSON.parse(props.dataset.responsive),
        {
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 4,
          xxl: 5,
        },
        (a, b) => (a === null ? b : a)
      );
      const { assets, loading, errors } = fetchAssets(
        settings,
        props.dataset.apiKey as string,
        props.dataset.hasApiKey === undefined
          ? false
          : props.dataset.hasApiKey.toLowerCase() === "true"
      );
      let error = ref<string>("");

      watch(errors, () => {
        if (
          errors.value.length > 0 &&
          props.dataset !== undefined &&
          props.dataset.translations !== undefined
        ) {
          error.value = JSON.parse(props.dataset.translations)[errors.value];
        }
      });

      return { assets, loading, error, responsiveFinal };
    }
    console.warn("[NFT Cards] Could not get settings!");
    const error = "[NFT Cards] Could not get settings!";
    const loading = false;
    return { loading, error };
  },
  provide() {
    return {
      hasSliderTitle: this.dataset === undefined || this.dataset.hasSliderTitle === undefined
          ? true : false,
      referralAnchor:
        this.dataset === undefined || this.dataset.refferal === undefined
          ? ""
          : `?ref=${this.dataset.refferal}`,
      wpPath:
        this.dataset === undefined || this.dataset.wpPath === undefined
          ? ""
          : this.dataset.wpPath,
      pluginPath:
        this.dataset === undefined || this.dataset.pluginPath === undefined
          ? ""
          : this.dataset.pluginPath,
      translations:
        this.dataset === undefined || this.dataset.translations === undefined
          ? {
              view_all: "View All",
              view_all_link: "#",
              slider_title: "Most Popular",
              make_offer: "Make Offer",
              floor_price: "Floor Price",
              from: "From",
              price: "Price",
              creator: "Creator",
              for_sale: "for sale",
              sold: "sold",
              buy_now: "buy now",
              description: "No description yet.",
              auction: "auction",
              last: "Last",
              unexpected: "An unexpected error occurred, we apologize.",
              throttled_req:
                "The request has been throttled by OpenSea. Please request an api key or wait for one hour.",
              config_error:
                "The plugin has not been configured correctly, please check documentation.",
            }
          : JSON.parse(this.dataset.translations),
    };
  },
  computed: {
    getType() {
      return this.dataset !== undefined ? this.dataset.type : 'single';
    }
  }
});
</script>

<style>
.mt-nft-cards-one-line-restrict {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
<style scoped>
* {
  font-family: "Montserrat", sans-serif;
}
.loading:empty {
  width: 100%;
  height: 250px;
  cursor: progress;
  background:
  linear-gradient(0.25turn, transparent, #fff, transparent),
  linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  background-size: 100% 250px, 100% 180px, 100px 100px;
  animation: loading 1.5s infinite;
}
@keyframes loading {
  to {
    background-position: 100% 0, 0 0, 0 190px, 50px 195px;
  }
}
</style>
