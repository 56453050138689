import { camelCase } from "lodash";
import axios from "axios";
// import { notification } from "ant-design-vue";
import { AxiosRequestConfig } from "axios";
import qs from "qs";

export const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/",
  timeout: 6000,
  headers: {
    "Content-type": "application/json",
  },
});
export async function getSingle<T>(
  endpoint: string,
  payload?: Object,
  config?: AxiosRequestConfig
): Promise<T> {
  const query = qs.stringify(payload);
  try {
    const { data } = await instance.get<T>(
      `/${endpoint}${payload === undefined ? "" : "?" + query}`,
      config
    );
    return data;
  } catch (error: any) {
    console.error(error);
    return error;
  }
}

export async function get<T>(
  endpoint: string,
  payload?: Object,
  config?: AxiosRequestConfig
): Promise<T> {
  const query = qs.stringify(payload);

  try {
    const { data } = await instance.get<T>(
      `/${endpoint}${payload === undefined ? "" : "?" + query.replaceAll(/%5B\d%5D/g, "").replaceAll(/%22/g, "")}`,
      config
    );
    return data;
  } catch (error: any) {
    console.error(error);
    return error;
  }
}

/** UTILITY FUNCTION */

export function camelizeKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
}
