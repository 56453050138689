import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f3311eda"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loading"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardsListView = _resolveComponent("CardsListView")!
  const _component_CardTwo = _resolveComponent("CardTwo")!
  const _component_CardThree = _resolveComponent("CardThree")!
  const _component_CardFour = _resolveComponent("CardFour")!
  const _component_CardOne = _resolveComponent("CardOne")!
  const _component_CardGridView = _resolveComponent("CardGridView")!
  const _component_NftCarousel = _resolveComponent("NftCarousel")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.dataset && _ctx.error.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.dataset.type === 'list')
                ? (_openBlock(), _createBlock(_component_CardsListView, {
                    key: 0,
                    assets: _ctx.assets
                  }, null, 8, ["assets"]))
                : (_ctx.dataset.type === 'grid')
                  ? (_openBlock(), _createBlock(_component_CardGridView, {
                      key: 1,
                      itemsPerRowResponsive: _ctx.responsiveFinal,
                      assets: _ctx.assets,
                      gutter: _ctx.dataset.gutter === '' ? 12 : _ctx.dataset.gutter
                    }, {
                      default: _withCtx((slotProps) => [
                        (_ctx.dataset.cardType === '2')
                          ? (_openBlock(), _createBlock(_component_CardTwo, {
                              key: 0,
                              asset: slotProps.asset
                            }, null, 8, ["asset"]))
                          : _createCommentVNode("", true),
                        (_ctx.dataset.cardType === '3')
                          ? (_openBlock(), _createBlock(_component_CardThree, {
                              key: 1,
                              asset: slotProps.asset
                            }, null, 8, ["asset"]))
                          : _createCommentVNode("", true),
                        (_ctx.dataset.cardType === '4')
                          ? (_openBlock(), _createBlock(_component_CardFour, {
                              key: 2,
                              asset: slotProps.asset
                            }, null, 8, ["asset"]))
                          : _createCommentVNode("", true),
                        (_ctx.dataset.cardType === '1')
                          ? (_openBlock(), _createBlock(_component_CardOne, {
                              key: 3,
                              asset: slotProps.asset
                            }, null, 8, ["asset"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["itemsPerRowResponsive", "assets", "gutter"]))
                  : (_ctx.dataset.type === 'carousel')
                    ? (_openBlock(), _createBlock(_component_NftCarousel, {
                        key: 2,
                        itemsPerRowResponsive: _ctx.responsiveFinal,
                        assets: _ctx.assets
                      }, {
                        default: _withCtx((slotProps) => [
                          (_ctx.dataset.cardType === undefined)
                            ? (_openBlock(), _createBlock(_component_CardOne, {
                                key: 0,
                                asset: slotProps.asset
                              }, null, 8, ["asset"]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                (_ctx.dataset.cardType === '2')
                                  ? (_openBlock(), _createBlock(_component_CardTwo, {
                                      key: 0,
                                      asset: slotProps.asset
                                    }, null, 8, ["asset"]))
                                  : (_ctx.dataset.cardType === '3')
                                    ? (_openBlock(), _createBlock(_component_CardThree, {
                                        key: 1,
                                        asset: slotProps.asset
                                      }, null, 8, ["asset"]))
                                    : (_ctx.dataset.cardType === '4')
                                      ? (_openBlock(), _createBlock(_component_CardFour, {
                                          key: 2,
                                          asset: slotProps.asset
                                        }, null, 8, ["asset"]))
                                      : (_openBlock(), _createBlock(_component_CardOne, {
                                          key: 3,
                                          asset: slotProps.asset
                                        }, null, 8, ["asset"]))
                              ], 64))
                        ]),
                        _: 1
                      }, 8, ["itemsPerRowResponsive", "assets"]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                        (_ctx.dataset.cardType === '1')
                          ? (_openBlock(), _createBlock(_component_CardOne, {
                              key: 0,
                              asset: _ctx.assets[0]
                            }, null, 8, ["asset"]))
                          : _createCommentVNode("", true),
                        (_ctx.dataset.cardType === '2')
                          ? (_openBlock(), _createBlock(_component_CardTwo, {
                              key: 1,
                              asset: _ctx.assets[0]
                            }, null, 8, ["asset"]))
                          : _createCommentVNode("", true),
                        (_ctx.dataset.cardType === '3')
                          ? (_openBlock(), _createBlock(_component_CardThree, {
                              key: 2,
                              asset: _ctx.assets[0]
                            }, null, 8, ["asset"]))
                          : _createCommentVNode("", true),
                        (_ctx.dataset.cardType === '4')
                          ? (_openBlock(), _createBlock(_component_CardFour, {
                              key: 3,
                              asset: _ctx.assets[0]
                            }, null, 8, ["asset"]))
                          : _createCommentVNode("", true)
                      ], 64))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.error), 1))
      ]))
    : _createCommentVNode("", true)
}