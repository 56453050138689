import {get} from "./api";
import {Asset} from "@/types";
import {camelizeKeys} from "./api";
import {ref, watch} from "vue";
import axios from "axios";
import qs from "qs";

export type API_Assets = {
    assets?: Asset[];
    detail?: string;
};

function removeEmpty(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
}

export type APISettings = {
    owner?: string;
    order_direction?: string;
    limit?: number;
    order_by?: string;
    asset_contract_addresses?: string[]
    token_ids?: string[]
    collection?: string
};

export default function (
    settings: APISettings,
    apiKey: string,
    hasAPIkey?: boolean
) {
    const assets = ref<Asset[]>([]);
    const loading = ref<boolean>(true);
    const errors = ref<string>('');
    const resultF = ref<API_Assets>({});
    const fetchAssets = () => {
        if (!hasAPIkey) {
            get<API_Assets>("assets", removeEmpty(settings)).then((result) => {
                assets.value = camelizeKeys(result.assets);
                loading.value = false;
                resultF.value = result;
            });
        } else {
            getRouted<API_Assets>(apiKey, removeEmpty(settings)).then(
                (result) => {
                    assets.value = camelizeKeys(result.assets);
                    loading.value = false;
                    resultF.value = result;
                }
            );
        }
    };
    fetchAssets();

    watch(resultF, () => {
        if (resultF.value.assets === undefined) {
            if (resultF.value.detail === undefined) {
                errors.value = "unexpected";
            } else {
                if (resultF.value.detail === "Request was throttled.")
                    errors.value = "throttled_req";
                else if (resultF.value.detail === "404") errors.value = "config_error";
                else errors.value = "unexpected";
            }
        }
    });

    return {
        fetchAssets,
        assets,
        loading,
        errors,
    };
}

export const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL + "/",
    timeout: 6000,
    headers: {
        "Content-type": "application/json",
    },
});

async function getRouted<T>(apikey: string, payload?: Object): Promise<T> {
    const query = qs.stringify(payload);
    try {
        const {data} = await instance.get<T>(
            `https://api.opensea.io/api/v1/assets${
                payload === undefined ? "" : "?" + query.replaceAll(/%5B\d%5D/g, "").replaceAll(/%22/g, "")
            }`,
            {
                headers: {
                    'X-API-KEY': apikey
                }
            }
        );
        return data;
    } catch (error: any) {
        console.error(error);
        return error;
    }
}
