import { createApp } from "vue";
import App from "./App.vue";
import FormatDate from "./functions/formatDate";
import { Row, Col, Tooltip } from "ant-design-vue";

import FontAwesomeIcon from "./icons/fontawesome-icons";
import './public-path.js'

const elems = document.querySelectorAll("[id^='app-']");
for (const elem of elems) {
  createApp(App, { dataset: (elem as HTMLElement).dataset})
    .use(FormatDate)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(Row)
    .use(Col)
    .use(Tooltip)
    .mount("#" + (elem as any).attributes["id"].nodeValue);
}
