// @ts-nocheck
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import {faDollarSign } from "@fortawesome/free-solid-svg-icons"
import {faImage } from "@fortawesome/free-solid-svg-icons"

library.add(faPhone);
library.add(faUser);
library.add(faFlag);
library.add(faHeart);
library.add(faDollarSign);
library.add(faImage);
export default FontAwesomeIcon;
